import React from 'react'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Bio from '../components/bio'
import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

class LinksIndex extends React.Component {
	render() {
		// console.log("props", this.props)
		const siteTitle = get(this, 'props.data.site.siteMetadata.title')

		const links = get(this, 'props.data.links.edges')
		const linksLinks = links.reduce((acc, link) => {
			const htmlWithoutParagraph = link.node.html.replace(
				/^<p>(.*)<\/p>$/g,
				'$1'
			)
			return [
				...acc,
				<li
					key={link.node.frontmatter.title}
					style={{
						marginBottom: rhythm(1 / 4),
					}}
				>
					<p>
						<a
							style={{ boxShadow: 'none' }}
							href={link.node.frontmatter.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							{link.node.frontmatter.title}
						</a>&nbsp;:&nbsp;
						<span
							dangerouslySetInnerHTML={{
								__html: htmlWithoutParagraph,
							}}
							style={{ p: { display: 'inline' } }}
						/>
					</p>
				</li>,
			]
		}, [])

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<Helmet
					title={get(this, 'props.data.site.siteMetadata.title')}
				/>
				<Bio />
				<div>
					<h2>Toutes les lectures d'Internet</h2>
					<ul>{linksLinks}</ul>
				</div>
			</Layout>
		)
	}
}

export default LinksIndex

export const pageQuery = graphql`
    query LinksQuery {
        site {
            siteMetadata {
                title
            }
        }
        links: allMarkdownRemark(
            filter: { frontmatter: { layout: { eq: "Link" } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        link
                    }
                    html
                }
            }
        }
    }
`
